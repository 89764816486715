<template>
  <div style='position: relative;'>
    <div class='button-group' v-if='!isShown'>
      <template v-if='!condensed'>
        <a href='#' v-if='!isCopied' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'><i class='fas fa-copy'></i>OTP</a>
        <a href='#' v-if='isCopied' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'>Copied!</a>
      </template>
      <template v-if="condensed">
        <a href='#' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'><i class='fas fa-lock'></i></a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["url", "condensed"],
  methods: {
    copy (e) {
      var that = this;
      
      that.$api.get(that.url)
      .then(function (response) {
        if (response.data["otp"]) {
          that.count = response.data["time_left"]
          that.otp = response.data["otp"];
          that.setClipboard(that.otp, e)
        } else {
          that.error = true;
          alert("OTP error")
        }
      }).catch(function (error) {
        that.error = true;
      })
    },
    setClipboard(value, e) {

      var that = this;

      Clipboard.copy(value);
      that.isCopied = true;
      
      setTimeout(function(){ that.isCopied = false; }, 2000);

      that.flash(`Copied OTP - ${that.count} second(s) left!`, 'success', 
        {
          timeout: 3000,
          important: true
        }
      );

      
    }
  },
  computed: {
    countPercentage () {
      return ((1 - (this.count / 30)) * 100)
    }
  },
  data () {
    return {
      active: false,
      isShown: false,
      option: 6,
      otp: "",
      isCopied: false,
      count: 30,
      error: false
    }
  }
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

