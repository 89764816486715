<template>
  <div>
    <div v-if='data.length > 0'>
      <h2 class='company-sidebar__title'>
        Custom
        <a href='#' v-if='!hideEmpty' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye-slash'></i>Hide Blank</a>
        <a href='#' v-if='hideEmpty' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye'></i>Show Blank</a></h2>
      <div href='#' v-for='folder in folders' class='company-sidebar__folder'>
        <a href='#' class='company-sidebar__folder__a' @click.prevent.stop='toggleFolder(folder.id)'>
          <div class='asset-icon'>
            <i class='fal fa-folder' v-if='isFolderHidden(folder.id)'></i>
            <i class='fal fa-folder-open' v-if='!isFolderHidden(folder.id)'></i>
          </div>
          <span class="company-sidebar__name">{{folder.name }}</span>
        </a>
        <a :href="d.url" v-if='showSidebarChild(folder.id, d.sidebar_folder_id)' v-for='(d, index) in filteredData' :class='{"selected": d.selected, "company-sidebar__folder-item": true, "company-sidebar__a": true}'>
          <div class="asset-icon" :style="{background: d.color}">
            <i :class="d.icon" :style="{color: d.icon_color}"></i>
          </div>
          <span class="company-sidebar__name">{{d.name}}</span>
          <span class="count">{{d.count}}</span>
        </a>
      </div>
      
      <a class='company-sidebar__a' :href="d.url" v-if='!d.sidebar_folder_id' v-for='(d, index) in filteredData' :class='{"selected": d.selected}'>
        <div class="asset-icon" :style="{background: d.color}">
          <i :class="d.icon" :style="{color: d.icon_color}"></i>
        </div>
        <span class="company-sidebar__name">{{d.name}}</span>
        <span class="count">{{d.count}}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "folders"],
  data () {
    return {
      hideEmpty: false,
      hides: []
    }        
  },
  mounted () {
    if (localStorage.getItem('huduHideEmptySidebarAssets')) {
      try {
        this.hideEmpty = true;
      } catch(e) {
        
      }
    }
    if (localStorage.getItem('huduSidebarFolderHides')) {
      try {
        this.hides = JSON.parse(localStorage.getItem('huduSidebarFolderHides'));
      } catch(e) {
        
      }
    }
  },
  methods: {
    showSidebarChild(folder_id, parent_id) {
      if ((!this.isFolderHidden(folder_id)) && (parent_id === folder_id))
        return true
      return false
    },
    isFolderHidden(id) {
      if (this.hides.includes(id))
        return true;
      return false
    },
    toggleFolder(id) {
      if(this.isFolderHidden(id)) {
        const index = this.hides.indexOf(id);
        if (index > -1) {
          this.hides.splice(index, 1);
          localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
        }
      } else {
        this.hides.push(id);
        localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
      }
    },
    toggleEmpty () {
      this.hideEmpty = !this.hideEmpty;

      if (this.hideEmpty === true) {
        localStorage.hide_assets = true
        localStorage.setItem('huduHideEmptySidebarAssets', true);
      } else {
        localStorage.removeItem('huduHideEmptySidebarAssets')
      }
      
      // if (this.hideEmpty === true) {
        
      //   localStorage.setItem('huduHideEmptySidebarAssets', false);
      // } else {
      //   this.hideEmpty = true;
      //   localStorage.setItem('huduHideEmptySidebarAssets', true);
      // }
    }
  },
  computed: {
    filteredData() {
      return this.hideEmpty ? this.data.filter(item => !(item.count == 0)) : this.data
    },
    filteredFolderData() {
      return this.filteredData.filter(item => item.sidebar_folder_id == this.activeFolderId)
    }
  }
}
</script>