import { render, staticRenderFns } from "./NewArticleButton.vue?vue&type=template&id=d73d5d60&"
import script from "./NewArticleButton.vue?vue&type=script&lang=js&"
export * from "./NewArticleButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports