var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"theme":_vm.dark_mode ? "nocturnal" : "default","styleClass":"vgt-table bordered striped condensed","pagination-options":{
  enabled: true,
  mode: 'records',
  perPage: 50,
  position: 'bottom',
  perPageDropdown: [10, 20, 50, 100, 200],
  dropdownAllowAll: false,
  setCurrentPage: 1,
  nextLabel: 'next',
  prevLabel: 'prev',
  rowsPerPageLabel: 'Items per page',
  ofLabel: 'of',
  pageLabel: 'page', // for 'pages' mode
  allLabel: 'All'
},"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('a',{attrs:{"href":props.row.url}},[(props.row.parent_company_name)?_c('span',{staticClass:"vgt__span-tag"},[_vm._v(_vm._s(props.row.parent_company_name)+" →")]):_vm._e(),_vm._v(_vm._s(props.row.name))])]):_c('span',[_vm._v("\n    "+_vm._s(props.formattedRow[props.column.field])+"\n  ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }