<template>
  <div class='bulk_user_inviter form-group'>
    <input type='hidden' name='user_emails' :value='JSON.stringify(users)'/>
    <header>
      <label>Enter Email(s)</label>
      <div class='bulk_user_inviter__input'>
        <input v-model='userEmails' type='email' class='input' placeholder='Enter Email Addresses separated by commas'>
        <a href='#' @click.prevent.stop='add' class='button button--success'><i class='fas fa-plus'></i>Add</a>
      </div>
    </header>
    <div v-if='users.length == 0'><p class='bulk_user_invites__invites__blank'>No users added...</p></div>
    <div v-if='users.length > 0' class='bulk_user_invites__invites'>
      <div v-for='(email,index) in users' class='bulk_user_invites__invite'>
        <a href='#' @click.prevent.stop='remove(index)'><i class='fas fa-trash'></i></a>
        <p>{{email}}</p>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false,
      userEmails: "",
      users: []
    }
  },
  methods: {
    validateEmail (email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    add () {
      let that = this;
      let emails = this.userEmails.split(",")

      emails.forEach(function(e) {

        e = e.toLowerCase().trim()

        if (that.validateEmail(e) && that.users.indexOf(e) === -1) {
          that.users.push(e)
        } 
      });

      this.userEmails = ""
    },
    remove (index) {
      this.users.splice(index, 1);
    }
  }
}
</script>

