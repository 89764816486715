<template>
  <div class='company-sidebar__resize' id='resizable'></div>
</template>

<script>
export default {
  props: [],
  data: function () {
    return {
      password: ""
    }
  },
  mounted () {

    var element = document.getElementById('company-sidebar');
    var that = this;
    var resizer = document.getElementById('resizable');
    let root = document.documentElement;
    let lowerBounds = 100;
    let upperBounds = 400;
    let localWidth = localStorage.getItem('huduCompanySidebarWidth')

    if (localWidth) {
      root.style.setProperty('--sidebarwidth', (localWidth) + 'px');
    }

    this.checkSkinny(localWidth, element)

    resizer.addEventListener('mousedown', initResize, false);

    function initResize(e) {
      e.preventDefault()
      window.addEventListener('mousemove', Resize, false);
      window.addEventListener('mouseup', stopResize, false);
    }

    function Resize(e) {
      e.preventDefault()
      let width = e.clientX - element.offsetLeft;

      if (width >= lowerBounds && width <= upperBounds) {
        element.style.width = (width) + 'px';
        root.style.setProperty('--sidebarwidth', (width) + 'px');
        localStorage.setItem('huduCompanySidebarWidth', width);

        that.checkSkinny(width, element)
      }
    }

    function stopResize(e) {
      window.removeEventListener('mousemove', Resize, false);
      window.removeEventListener('mouseup', stopResize, false);
    }
  },
  methods: {
    checkSkinny (width, element) {
      if (width < 200) {
        element.classList.add("company-sidebar--skinny")
      } else {
        element.classList.remove("company-sidebar--skinny")
      }
    }
  },
}
</script>

