<template>
  <div>
    <a href='#' class='button button--large button--block newkb__sidebar__buttons--button button--secondary' @click.prevent.stop='isShown = true'><i class='fas fa-tools'></i>Bulk Manage</a>
    <div class='manage_modal__wrapper' v-if='isShown'>
      <flash-message></flash-message>
      <div class='manage_modal' v-click-outside="onClickOutside">
        <header>
          <h1><i class='fas fa-tools icon'></i>Bulk Manage</h1>
          <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
        </header>

        <div class='bulk-manage__header'>
          <h2>{{selected_num}} selected</h2>
          <div class='button-group' v-if='!showMove'>
            <a href='#' @click.prevent.stop='toggleGlobalMove' v-if='selected_num > 0' class='button--secondary button'><i class="fas fa-truck-moving"></i>Move to Global...</a>
            <a href='#' @click.prevent.stop='toggleMove' v-if='selected_num > 0' class='button--secondary button'><i class="fas fa-truck-moving"></i>Move to {{structure_name}}...</a>
            <a href='#' @click.prevent.stop='archiveRecords' v-if='selected_num > 0' class='button--secondary button'><i class='fas fa-archive'></i>Archive</a>
            <a href='#' @click.prevent.stop='deleteRecords' v-if='selected_num > 0' class='button--danger button'><i class='fas fa-trash'></i>Permanent Delete</a>
          </div>
        </div>
        <div class='bulk-manage__panel' v-if='showMove && selected_num > 0'>
          <h1>Move to {{structure_name}}...</h1>

          <form @submit.prevent.stop>
            <company-grabber  @added_company="companyAdded" @removed_company="companyRemoved" name='company_id' :existing="{name: '', id: ''}" :label='structure_name'></company-grabber>

            <folder-chooser 
              v-if='folders.length > 0' 
              v-on:changeFolder="folderWasChanged" 
              :folders='folders'></folder-chooser>
            <div class='button-group'>
              <button class='button--secondary button' @click.prevent.stop='moveRecords'><i class="fas fa-truck-moving"></i>Move Selected Records</button>
            </div>
            </form>
        </div>
        <div class='bulk-manage__panel' v-if='showGlobalMove && selected_num > 0'>
          <h1>Move to Global...</h1>

          <form @submit.prevent.stop>
            <folder-chooser v-if='folders' v-on:changeFolder="folderWasChanged" 
              :folders='folders'></folder-chooser>
            <div class='button-group'>
              <button class='button--secondary button' @click.prevent.stop='moveRecordsToGlobal'><i class="fas fa-truck-moving"></i>Move Selected Records</button>
            </div>
            </form>
        </div>
        <div style='margin: 1rem auto;' v-if='loading'><loader></loader></div>
        <table class='bulk-manage' v-if='!loading'>
          <thead>
            <tr>
              <th><input type='checkbox' v-model="selectAll"></th></th>
              <th class='bulk-manage__td-name'>{{name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='record in records'>
              <td><input type='checkbox' v-model="ids" :value="record.id"></th></td>
              <td class='bulk-manage__td-name'>{{record.name}}</td>
              <td>
                <div class='button-group'>
                  <a :href='record.url' target='_blank' class='button button--secondary'><i class='fas fa-external-link-alt'></i>Open in New Tab</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import loader from './loader.vue';
import CompanyGrabber from './company_grabber.vue'
import vClickOutside from 'v-click-outside'
import FolderChooser from './folder_chooser.vue';

export default {
  props: ["name", "url", "structure_name", "current_company_id", "folder_structure_path", "folder_existing_name", "folder_value"],
  components: {
    loader,
    CompanyGrabber,
    FolderChooser
  },
  mounted () {
    var that = this;

    that.$api.get(that.url)
    .then(function(response) {
      that.records = response.data
    }).catch(error => {
      alert(error.response.data)
    })
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    },
    folderWasChanged (value) {
      this.moveFolderId = value
      console.log(value)
    },
    companyAdded (value) {
      var that = this;
      that.moveCompanyId = value.id

      that.getFolders(value.id)
    },
    companyRemoved (value) {
      this.folders = []
    },
    getFolders (company_id) {
      var that = this;
      var url = company_id ? `${that.folder_structure_path}?company_id=${company_id}` : that.folder_structure_path
      that.$api.get(url, 
        { 
          headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
          that.folders = response.data
      }).catch(error => {

        that.flash("Something went wrong!", 'error', 
          {
            timeout: 3000,
            important: true
          }
        );
        console.log(error)
      })
    },
    addSelected(id) {
      this.ids.push(id)
    },
    toggleMove () {
      this.folders = []
      this.showMove = !this.showMove;
      this.showGlobalMove = false;
    },
    toggleGlobalMove () {
      this.folders = []
      this.showGlobalMove = !this.showGlobalMove;
      this.showMove = false;
      this.getFolders();
    },
    moveRecordsToGlobal () {
      var that = this;

      that.loading = true;
      if(confirm(`Are you sure you want to move ${this.ids.length} records ?`)){
        that.$api.post('/move_records.json', 
          {
            "record_type": "Article",
            "records": that.ids,
            "new_company_id": null,
            "new_folder_id": that.moveFolderId
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.ids.forEach(function (selected_value) {

            that.records.splice(that.records.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.ids = []
          that.loading = false
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
    moveRecords (submitEvent) {
      var that = this;

      that.loading = true;
      if(confirm(`Are you sure you want to move ${this.ids.length} records ?`)){
        that.$api.post('/move_records.json', 
          {
            "record_type": "Article",
            "records": that.ids,
            "new_company_id": that.moveCompanyId,
            "new_folder_id": that.moveFolderId
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.ids.forEach(function (selected_value) {

            that.records.splice(that.records.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.ids = []
          that.loading = false
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
    archiveRecords () {
      var that = this;

      that.loading = true;
      if(confirm(`Are you sure you want to archive ${this.ids.length} records ?`)){
        that.$api.post('/archive_records.json', 
          {
            "record_type": "Article",
            "records": that.ids
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.ids.forEach(function (selected_value) {

            that.records.splice(that.records.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.ids = []
          that.loading = false
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
    deleteRecords () {
      var that = this;
      that.loading = true;

      if(confirm(`Are you sure you want to delete ${this.ids.length} records ?`)){
        that.$api.post('/delete_records.json', 
          {
            "record_type": "Article",
            "records": that.ids
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.ids.forEach(function (selected_value) {

            that.records.splice(that.records.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.ids = []
          that.loading = false;
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    }
  },
  computed: {
    selectAll: {
      get: function () {
          return this.records ? this.ids.length == this.records.length : false;
      },
      set: function (value) {
          var ids = [];

          if (value) {
              this.records.forEach(function (record) {
                  ids.push(record.id);
              });
          }

          this.ids = ids;
      }
    },
    selected_num () {
      return this.ids.length
    }
  },
  data () {
    return {
      records: [],
      loading: false,
      isShown: false,
      folders: [],
      showMove: false,
      showGlobalMove: false,
      moveCompanyId: "",
      moveFolderId: "",
      ids: []
    }
  }
}
</script>