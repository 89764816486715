
<template>
  <div>
    <a href='#' @click.prevent.stop='isShown = !isShown' class='newkb__sidebar__buttons--button button button--large button--block button--secondary'><i class='fas fa-cog'></i>Edit Folder</a>
    <transition name='popopen'>
      <slot v-if='isShown'>
      </slot>
    </transition>
  </div>
</template>

<script>


export default {
  props: [],
  data () {
    return {
      isShown: false
    }
  },
  methods: {
    
  },

}
</script>

