<template>
  <article class='hemingway__split-content'>

    <div>
      <div id='contents'>
        <slot name='content'></slot>
      </div>
    </div>
    <div class='hemingway__toc--blank' v-if='toc.length == 0'></div>
    <div class='hemingway__toc' v-if='toc.length > 0'>
      <div id='toc'>

        <ul>
          <a class='hemingway__toc__title' v-if='isShown' @click.prevent.stop='isShown = !isShown' href='#'><i class='fas fa-caret-down'></i>Table of Contents</a>
          <a class='hemingway__toc__title' v-if='!isShown' @click.prevent.stop='isShown = !isShown' href='#'><i class='fas fa-caret-right'></i>Table of Contents</a>
          <li v-if='isShown' :class='`toc${t.tag}`' v-for='t in toc'><a :href='`#${t.id}`'>{{t.name}}</a></li>
        </ul>
      </div>
    </div>
   
    
    
  </article>
</template>

<script>

export default {
  props: ["url", "text", "read_time", "no_siblings"],
  data: function () {
    return {
      isShown: true,
      toc: []
    }
  },
  mounted () {
    this.getContents();
  },
  methods: {
    toggleContent (e) {
      if (this.isShown == false) {
        this.getContents()
        this.isShown = true;
      } else {
        this.isShown = false;
      }
    },
    getContents () {
      var that = this;
      var headers = document.getElementById("contents").querySelectorAll("h1, h2, h3, h4, h5, h6")
      headers.forEach(function (element) {
        var tag = element.tagName;
        console.log(tag)
        var readable = element.textContent;
        var id = readable.replace(/\s+/g, '-')
        console.log(id)
        element.setAttribute("id", id);
        that.toc.push({tag: tag, name: readable, id: id})
      })
    }
  },
}
</script>

